import {RemixBrowser, useLocation, useMatches} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import {startTransition, StrictMode, useEffect} from 'react';
import {hydrateRoot} from 'react-dom/client';

Sentry.init({
  dsn: 'https://79dfccd0c56343874955fbd54a634b76@o4506702475558912.ingest.us.sentry.io/4506702524579840',
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
